<template>
  <div>
    <AddRecipientTypeModal
      v-model="addRecipient.showSelectType"
      @addMyself="addRecipient.addMyBankAccount()"
      @addSomeoneElse="addRecipient.addSomeoneElse()"
      @addBusiness="addRecipient.addBusiness()"
      @close="$router.go(-1)"
    />
    <ModifyRecipientModal
      v-if="addRecipient.showAddRecipient"
      v-model="addRecipient.showAddRecipient"
      :myself="addRecipient.type.myself"
      :business="addRecipient.type.business"
      :confirm-text="$t('PageRecipients.ButtonCreate').value"
      @success="onSuccessfulAddRecipient"
      @back="(isClose) => addRecipient.backFromAddRecipient(isClose)"
    />
  </div>
</template>

<script>
import { useSendMoneyStore, useI18nStore } from '@galileo/stores'
import { reactive } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import AddRecipientTypeModal from '@galileo/components/Views/Recipient/AddRecipientTypeModal'
import ModifyRecipientModal from '@galileo/components/Views/Recipient/ModifyRecipientModal'

export default {
  name: 'AddRecipient',
  components: {
    AddRecipientTypeModal,
    ModifyRecipientModal,
  },
  props: {
    shouldSetRecipient: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const router = useRouter()

    const addRecipient = reactive({
      showSelectType: true,
      showAddRecipient: false,
      addRecipient() {
        this.showSelectType = true
      },
      type: {
        myself: false,
        business: false,
      },
      backFromAddRecipient(isClose = false) {
        this.showAddRecipient = false
        this.showSelectType = !isClose
        if (!this.showSelectType) {
          router.go(-1)
        }
      },
      addMyBankAccount() {
        this.addBusiness()
        this.type.myself = true
        this.type.business = true
      },
      addSomeoneElse() {
        this.resetType()
        this.showAddRecipient = true
      },
      addBusiness() {
        this.resetType()
        this.type.business = true
        this.showAddRecipient = true
      },
      //helper
      resetType() {
        this.showSelectType = false
        this.type.myself = false
        this.type.business = false
      },
    })

    const onSuccessfulAddRecipient = (newRecipientResponse) => {
      addRecipient.showAddRecipient = false
      if (props.shouldSetRecipient) {
        sendMoneyStore.form.recipient = newRecipientResponse
        sendMoneyStore.setAvailableCurrencies(newRecipientResponse.country)
        sendMoneyStore.form.countryTo = newRecipientResponse.country
        sendMoneyStore.form.currencyTo = newRecipientResponse.currency
      }
      router.go(-1)
    }

    return {
      $t,
      addRecipient,
      onSuccessfulAddRecipient,
    }
  },
}
</script>

<style scoped></style>
